<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <div class="search">
        <div class="search-body">
          <a-form-model layout="inline">
            <a-form-model-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear style="width: 120px">
                <a-select-option :value="0">待提交</a-select-option>
                <a-select-option :value="1">已提交</a-select-option>
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">不通过</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-model-item>
            <a-form-model-item label="外出类型">
              <a-select v-model="search.outType" placeholder="请选择" allowClear style="width: 120px">
                <!-- <a-select-option value="">全部</a-select-option> -->
                <a-select-option value="个人">个人</a-select-option>
                <a-select-option value="因公">因公</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <p-button type="primary" name="搜索" @click="searchList" />
            </a-form-model-item>
            <a-form-model-item style="float: right">
              <a-button type="primary" style="margin-right: 10px" @click="gotonew">新增</a-button>
              <a-button type="default" @click="exportData">导出</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="tableItem">
        <a-table rowKey="id" :columns="tableInfo.columns" :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination" @change="onChange" :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }">
          <template slot="status" slot-scope="info">
            <processStatus :processModel="info" />
          </template>
          <span slot="action" slot-scope="action">
            <template v-if="action.creator == currUser">
              <a-button v-if="action.status == 0 || action.status == 3" @click="addOrUpdate(action)" size="small"
                type="primary">修改</a-button>
              <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeStatus(action.id, 1)"
                v-if="action.status == 0 || action.status == 3">
                <a-button size="small" type="danger">提交</a-button>
              </a-popconfirm>
              <a-popconfirm v-if="action.status == 0 || action.status == 3" title="确定要删除吗？"
                @confirm="deleteItem(action.id)">
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </template>
            <a-button @click="addOrUpdate(action)" size="small" type="primary" v-if="
              action.processExecuteStatus && action.taskKey == '其他-审批'
            ">审批</a-button>
            <a-button @click="gotoloanbill(action)" size="small" type="primary"
              v-if="action.status == 2 && action.outType == '因公'">报销</a-button>
            <a-button @click="addOrUpdate(action)" size="small" type="default" v-if="action.status == 2">打印</a-button>
            <a-button @click="addOrUpdate(action)" size="small" type="default"
              v-if="action.status == 1">详情</a-button>
          </span>
        </a-table>
      </div>
      <a-modal :title="editor.title" v-model="editor.visible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
        <businessoutinfo :info="editor.info" :modal="true" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import businessoutApi from "@/api/work/businessout";
import businessoutinfo from "./businessoutinfo.vue";

var _this;
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    customRender: (text, record, index) => {
      return (
        (_this.tableInfo.pagination.current - 1) *
        _this.tableInfo.pagination.defaultPageSize +
        index +
        1
      );
    },
  },
  {
    dataIndex: "orgName",
    key: "orgName",
    align: "center",
    title: "部室",
  },

  {
    dataIndex: "outUserName",
    key: "outUserName",
    align: "center",
    title: "外出人",
  },
  {
    key: "time",
    align: "center",
    title: "外出时间",
    customRender: (text, record, index) => {
      return record.startTime + "-" + record.endTime;
    },
  },
  {
    dataIndex: "days",
    key: "days",
    align: "center",
    title: "外出天数",
  },
  {
    dataIndex: "duties",
    key: "duties",
    align: "center",
    title: "职务",
  },
  {
    dataIndex: "outType",
    key: "outType",
    align: "center",
    title: "外出类型",
  },
  {
    dataIndex: "description",
    key: "description",
    align: "center",
    title: "外出事由",
  },
  {
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
    title: "状态",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "businessout",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {},
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          current: 1,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
        // tableHeight: window.innerHeight - 300,
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      itemListInfo: {
        title: "",
        info: {},
        visible: false,
      },
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  components: {
    businessoutinfo,
  },
  mounted() {
    _this = this;
    this.getPageList();
  },
  methods: {
    gotoloanbill(info) {
      this.$router.push({ name: "travelexpense", params: { ...info } });
    },
    gotonew() {
      localStorage.removeItem("businessoutinfo");
      this.$router.push({ name: "businessoutinfo", params: { status: 0 } });
    },
    deleteItem(id) {
      this.loading = true;
      businessoutApi.deleteItem(id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    exportData() {
      var _this = this;
      _this.loading = true;
      businessoutApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    async getPageList() {
      let _this = this;
      let param = {
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.defaultPageSize,
        ..._this.search,
      };
      businessoutApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.search.startTime = dateString[0];
      this.search.endTime = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.search.pageNow = page.current;
      _this.search.pageSize = page.pageSize;
      _this.tableInfo.pagination.current = page.current;
      _this.tableInfo.pagination.defaultPageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = "查看";
        }
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        info = {
          id: null,
        };
        _this.editor.info = info;
      }
    },
    async changeStatus(id, status) {
      this.loading = true;
      businessoutApi.changeStatus(id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    showList(info) {
      //打开弹出框
      let _this = this;
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title = "字典子集";
      _this.itemListInfo.info = info;
    },
    modelCallBack(f) {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>

<style lang="less"></style>
